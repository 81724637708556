<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition">
          <div class="form-label-title">
            合同信息
          </div>
          <el-form-item label="合同名称:">
            <span>{{ form.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="合同编号:" >
            <span>{{ form.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="所属标段:">
            <span>{{ form.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="合同金额:">
            <span>{{ form.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="甲方单位:">
            <span>{{ form.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="合同周期:">
            <span>{{ form.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="合同附件上传:" style="width: 100%">
            <img src="" alt="">
          </el-form-item>

          <!-- 审核结果 -->
          <div class="form-label-base">
            审核结果
          </div>
          <el-form-item label="审核结果:" style="width: 100%">
            <el-radio v-model="form.radio" label="通过">通过</el-radio>
            <el-radio v-model="form.radio" label="不通过">不通过</el-radio>
          </el-form-item>
          <el-form-item label="审核意见" style="width: 100%">
            <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="审核人员" style="width: 100%">
            <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="submitForm()">整体提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        submitLoading: false,
        form: {
            cargoArrivalNumber: '测试',
            radio: null,
        },
        rules: {
          "areaUunitPrice.cargoArrivalNumber": {required: true, trigger: 'blur'},
        },
        
      }
    },
    methods: {
        submitForm() {}
    },

    async mounted() {
        
    },
  }
</script>

<style lang='sass' scoped>
.form-label-title
    color: #2E3033
    font-size: 16px
    margin: 0 0 20px 0
    padding-left: 10px
    border-left: 2px solid #1ABC9C

.form-label-test
    font-size: 14px
    color: rgba(126,129,135,1)
    line-height: 46px

/deep/.form-label-base
    margin: 20px 0 10px
</style>
